<template>
  <b-container id="verify-order-form" @keypress="handleEnterTabbing">
    <div
      v-if="!connectedToProduction"
      class="verify-order-dev-info"
    >
      <h6 class="mt-0">
        Verify Order Form Dev Tools
      </h6>
      <b-checkbox v-model="editMode">
        Edit Mode
      </b-checkbox>
      wrapperValid: {{ wrapperValid }}
    </div>

    <ct-centered-spinner v-if="loading">
      {{ loadingText }}
    </ct-centered-spinner>
    <b-card-body v-if="dataFullyLoaded" v-show="!loading" class="pt-2">
      <schema-form
        v-show="editMode"
        :key="`schema-form-${schemaFormKey}`"
        :ref="`filing-form-${ item.id }`"
        v-model="formValues"
        :fields="form"
        :contextual-jurisdiction="contextualJurisdiction"
        :suggestion-fields="suggestionFields"
        :hide-disclaimer="true"
        @input="dirty = true"
        @ra-signup="raSignUp"
        @show-contact-modal="showContactModal"
        @suggestion-toggled="suggestionToggled($event)"
      />
      <schema-form-data-viewer
        :edit-mode="editMode"
        :header-text="null"
        :schema-viewer-data="dataViewerData"
        :show-additional-official-data="false"
        :form="form"
      />
    </b-card-body>
    <contact-modal ref="contact-modal" :title="'Add a contact'" :type="'add'" />
    <b-modal
      id="cancel-form-edit-modal"
      ref="cancel-form-edit-modal"
      title="Cancel Changes"
      size="md"
      @cancel="cancelCancelled"
      @ok="cancelConfirmed"
    >
      Are you sure you want to cancel? Any changes you've made will not be saved.
      <template #modal-cancel>
        Continue editing
      </template>
      <template #modal-ok>
        Cancel changes
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as helper from '@/components/SchemaForm/helper.js'
import { normalizeOfficial } from '@/components/SchemaForm/schemaViewerHelper'
import _ from 'lodash'

export default {
  name: 'VerifyOrderForm',
  components: {
    SchemaForm:           () => import('@/components/SchemaForm'),
    ContactModal:         () => import('@/components/ContactModal'),
    CtCenteredSpinner:    () => import('@/components/shared/CtCenteredSpinner'),
    SchemaFormDataViewer: () => import('@/components/SchemaForm/SchemaFormDataViewer'),
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      contextualJurisdiction: null,
      form: {},
      cachedFormValues: {},
      formValues: {},
      loading: true,
      dataFullyLoaded: false,
      suggestionFields: [],
      editMode: false,
      dirty: false,
      onScreenErrors: true,
      loadingText: 'Loading order details...',
      formDidValidate: false,
      schemaFormKey: 0,
    }
  },
  computed: {
    ...mapGetters('app', [
      'connectedToProduction',
    ]),
    ...mapGetters('account', [
      'people',
    ]),
    ...mapGetters('jurisdictions', [
      'findByName',
    ]),
    ...mapGetters('schema', [
      'schema',
      'wrapperValid',
    ]),
    ...mapGetters('verifyOrder', [
      'orderRequiringVerificationType',
      'orderRequiringVerification',
    ]),
    ...mapGetters('stageline', [
      'pdfViewerAgencyResourceId',
    ]),
    dataViewerData() {
      const data = {}
      this.form.forEach(item => {
        if (!['hidden', 'disclaimer'].includes(item?.type)) {
          if (item.name.includes('official.')) {
            const officialArray = []

            const fields = item?.fields || item?.form_fields || [] // Need both to ensure it works with old and new schema forms
            const fieldParts = new Set(fields.map(field => field.name).filter(Boolean))

            for (const [formValuesKey, formValuesValue] of Object.entries(this.formValues)) {
              if (formValuesKey.includes(item.name)) {
                Array.isArray(formValuesValue) ?
                  formValuesValue.forEach(official => {
                    const normalizedOfficial = normalizeOfficial(formValuesKey, official, fieldParts)
                    officialArray.push(normalizedOfficial)
                  }) : officialArray.push(normalizeOfficial(formValuesKey, formValuesValue, fieldParts))
              }
            }
            data[item.name] = officialArray
          } else {
            const incompleteData =
              (item.name === 'filer' && !(this.formValues[item.name]?.first_name || this.formValues[item.name]?.last_name)) ||
              (item.name === 'registered_agent' && !this.formValues[item.name]?.name)
            data[item.name] = !this.formValues[item.name] || incompleteData ? '-' : this.formValues[item.name]
          }
        }
      })
      return data
    },
  },
  watch: {
    wrapperValid(newVal) {
      // Set to edit mode only on first failed wrapper validation after component has mounted
      if (!newVal && !this.formDidValidate) {
        this.validateForm({ showErrors: false })
        this.editMode = true
        this.formDidValidate = true
      }
    },
  },
  async mounted() {
    if (!this.item?.jurisdiction) return
    this.loading = true
    this.$emit('loading')

    await this.setCurrentCompany({ id: this.item.company.id })
    this.setJurisdiction()
    await this.setForm()
    this.removeCounty()
    await this.hydrateForm()

    this.editMode = !this.wrapperValid
    this.loading = false
    this.dataFullyLoaded = true

    this.$emit('loaded')
  },
  beforeDestroy () {
    this.setVerifyOrderPeople([])
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('checkout', [
      'addToCart',
      'setupContext',
      'fetchProduct',
    ]),
    ...mapActions('stagelineSchemaForm', [
      'setVerifyOrderPeople',
    ]),
    ...mapActions('schema', [
      'loadSchema',
      'setObject',
      'setShowWrapperValidationErrors',
      'loadFilingProductSchemaByFilingMethod',
    ]),
    ...mapActions('verifyOrder', ['logVerifyOrderInteraction']),

    scrollToAndFocusFirstInvalidField() {
      this.$nextTick(() => {
        const el        = this.$el.querySelector('.validation-wrapper-error'),
          input         = el?.previousElementSibling,
          schemaElement = el?.closest('.schema-element')
        input?.focus({ preventScroll: true })
        schemaElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    },
    handleEnterTabbing(e) {
      if (e.key !== 'Enter') return
      const fieldList = Array.from(document.getElementById('verify-order-form')
        ?.querySelectorAll('input, select'))
        .filter(element =>
          !element.closest('.schema-field__hidden') && !element.disabled &&
          !element.parentElement.disabled && !element.parentElement.parentElement.disabled
        )
      const currentIndex = fieldList.indexOf(document.activeElement)
      if (!fieldList.length || !document.activeElement || currentIndex < 0) return
      const previousElement = fieldList[currentIndex - 1]
      const nextElement = fieldList[currentIndex + 1]
      if (e.shiftKey && previousElement) previousElement.focus()
      if (!e.shiftKey && nextElement) nextElement.focus()
      if (!e.shiftKey && !nextElement) document.getElementById('submit-btn').click()
    },

    setJurisdiction() {
      this.contextualJurisdiction =
        typeof(this.item.jurisdiction) === 'object' && !!this.item.jurisdiction ?
          this.item.jurisdiction :
          this.findByName(this.item.jurisdiction)
    },

    async setForm() {
      if (this.orderRequiringVerificationType === 'ShoppingBasketItem') {
        const pdfResourceId = this.pdfViewerAgencyResourceId || null

        await this.loadFilingProductSchemaByFilingMethod({
          filingMethodId: this.orderRequiringVerification.filing_method_id,
          pdfResourceId: pdfResourceId,
        })
      } else {
        await this.loadSchema('stageline')
      }
      this.form = this.schema
    },
    async hydrateForm() {
      const details = {
        ...this.item.company.details,
        ...this.item.data.default,
      }

      await this.updatePeopleFromOrderItemData(details)
      this.formValues = {}
      const [formValues, suggestionFields] = helper.fillFormFromDetails(this.form, details)
      if (!_.isEmpty(formValues)) {
        this.formValues = formValues
        this.formValues = helper.normalizeOfficials(this.form, this.formValues)
        this.form.values = this.formValues
      }
      this.removeHomeStateFromFormValues()

      this.form = this.makeFilerNotRequired()

      if (Object.keys(suggestionFields).length) this.suggestionFields = suggestionFields

      // Cache original form values in case the user wants to clear their changes
      this.cachedFormValues = this.formValues
    },
    makeFilerNotRequired() {
      // quick fix to prevent Filer being required when the bug happens where the disabled filer fields don't prefill
      return this.form.map(formItem => {
        if (formItem.name !== 'filer') return formItem
          formItem.required = false
          formItem.fields.map(field => {
            field.required = false
            return field
          })
          return formItem
      })
    },
    removeCounty() {
      this.form = helper.removeCountyFields(this.form)
    },
    async updatePeopleFromOrderItemData(details) {
      const updatedPeople = this.people
      const addPerson = (person) => {
        const personIndex = updatedPeople.findIndex(p =>
          p.first_name && person.first_name && p.last_name && person.last_name &&
          p.first_name.trim() === person.first_name.trim() && p.last_name.trim() === person.last_name.trim())
        if (personIndex >= 0) {
          updatedPeople[personIndex].person_addresses = [person.address]
        } else {
          if (person.first_name && person.last_name && person.first_name.trim() && person.last_name.trim()) {
            updatedPeople.push({
              contact_company_name: null,
              contact_is_company: null,
              first_name: person.first_name,
              id: null,
              last_name: person.last_name,
              person_addresses: [person.address],
              person_emails: [{}],
              person_phones: [{}],
              substitute_ra_address: null,
            })
          }
        }
      }

      for (const [key, value] of Object.entries(details)) {
        if (key.includes('official') && !key.includes('_')) {
          const personIndex = updatedPeople.findIndex(p => p.first_name === value.first_name && p.last_name === value.last_name)
          if (personIndex >= 0) updatedPeople[personIndex].person_addresses = [value.address]
          Array.isArray(value) ? value.forEach(official => addPerson(official)) : addPerson(value)
        }
      }
      await this.setVerifyOrderPeople(updatedPeople)
    },
    // Hack - we do not want to show home state in the ORA, but we want to show it everywhere else.
    removeHomeStateFromFormValues(){
      this.form = this.form.filter(e => e.name !== 'home_state')
    },
    validateForm({ showErrors=false }) {
      if (!this.wrapperValid) {
        if (showErrors) {
          this.$bvToast.toast('Please fill out the rest of the required information.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }

        this.setShowWrapperValidationErrors(showErrors)
        this.scrollToAndFocusFirstInvalidField()

        return false
      }
      return true
    },
    async saveDetails() {
      this.setShowWrapperValidationErrors(false)
      this.loadingText = 'Saving order details...'
      this.loading = true
      this.$emit('loading')

      const result = await helper.saveDetails({
        form: this.form,
        formValues: this.formValues,
        item: this.item,
        formCompleted: false,
      })

      this.cachedFormValues = this.formValues

      if (result[0].success && result[0].orderItemData) {
        this.updateOrderItemData(result[0].orderItemData)
      }

      this.$bvToast.toast(result[0].message, {
        title:  result[0].success ? 'Success' : 'Error',
        variant: result[0].success ? 'success' : 'danger',
        solid: true,
      })

      this.$nextTick(() => {
        this.loading = false
        this.$emit('loaded')
      })
      return true
    },
    updateOrderItemData(data) {
      this.formValues = {}
      const [formValues, suggestionFields] = helper.fillFormFromDetails(this.form, data.default, false)

      if (!_.isEmpty(formValues)) {
        this.formValues = formValues
        this.form.values = this.formValues
      }
      this.removeHomeStateFromFormValues()
      if (Object.keys(suggestionFields).length) this.suggestionFields = suggestionFields
      this.form.values = this.formValues
    },
    suggestionToggled(event) {
      if (event.field.meta && event.field.meta.type === 'address') {
        if (event.usingSuggestion === false) {
          delete this.suggestionFields[event.field.name]
        }
      }
    },
    async raSignUp() {
      await this.logVerifyOrderInteraction('ra-sign-up')
      let raProduct = await this.fetchProduct({
        productKind: 'registered_agent_product',
        jurisdictionId: this.contextualJurisdiction.id,
      })
      if (raProduct.length) {
        raProduct = raProduct[0]
        await this.addToCart( { ...raProduct, skipResolveOptionalItems: true } )
      }
    },
    showContactModal() {
      this.$refs['contact-modal'].show()
    },
    async cancelButtonClicked() {
      await this.logVerifyOrderInteraction('cancel-form-changes')
      if (!this.validateForm({ showErrors: true })) return
      if (this.dirty) {
        this.$bvModal.show('cancel-form-edit-modal')
      } else {
        this.dirty = false
        this.editMode = false
      }
    },
    revertFormValuesToCached() {
      this.formValues = this.cachedFormValues
      this.form.values = this.cachedFormValues

      // Force re-render schemaForm
      this.schemaFormKey ++
    },
    async cancelConfirmed() {
      await this.logVerifyOrderInteraction('cancel-form-changes-confirmed')
      this.revertFormValuesToCached()

      this.dirty = false
      this.editMode = false
    },
    async cancelCancelled() {
      await this.logVerifyOrderInteraction('cancel-form-changes-cancelled')
      this.$bvModal.hide('cancel-form-edit-modal')
    },
    async editButtonClicked({ showErrors=false, forceEditMode=false }) {
      if (forceEditMode) this.editMode = true

      if (this.editMode) {
        await this.logVerifyOrderInteraction('save-form-changes')
        if (!this.validateForm({ showErrors: true })) {
          return
        }
        if (this.dirty) {
          const accepted = await this.saveDetails()
          if (!accepted) return
        }
        this.editMode = false
      } else {
        await this.logVerifyOrderInteraction('edit-form-changes')
        this.setShowWrapperValidationErrors(showErrors)
        this.dirty = false
        this.editMode = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>

#verify-order-form {
  margin-top: 40px;
  min-height: 140px;
  ::v-deep {
    .form-control,
    .custom-select {
      border: none !important;
      border-bottom: 2px grey solid !important;
      border-radius: 0 !important;
      min-height: 40px !important;
      &[disabled='disabled'] {
        border: none !important;
      }
    }
  }

  .edit-link {
    color: $ct-ui-primary !important;
    text-decoration: underline !important;
    cursor: pointer;
  }

  ::v-deep .schema-form > .schema-element:last-child hr {
    display: none;
  }

  ::v-deep .schema-form__validation-message {
    display: none;
  }

  > hr {
    margin: 3em 1.25em;
  }

  ::v-deep #schema-form-data-viewer .col-md-6 {
    max-width: 100%;
  }

  @media only screen and (max-width: 660px) {
    .custom-select {
      width: 100% !important;
    }
  }

  .verify-order-dev-info {
    opacity: .9;
    font-size: .7em;
    min-height: 25px !important;
    position: fixed;
    top: 80px;
    right: 20px;
    z-index: 100000;
    background: white;
    border: black solid 2px;
    padding: 2px;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
